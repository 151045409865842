<template>
  <div class="mt-2">
    <div class="w-full mt-4 mb-2">
      <Header v-model="filters" />
    </div>
    <div class="w-full mt-4 mb-2">
      <Table :filters="filters" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'IndexRips',
  components: {
    Header: defineAsyncComponent(
      () => import('./components/Header.vue')
    ),
    Table: defineAsyncComponent(
      () => import('./components/Table.vue')
    )
  },
  setup () {
    const store = useStore()
    const user = computed(() => store.getters['auth/getPeople'])
    const filters = reactive({
      year: '',
      month_start: '01',
      month_end: '12',
      createdby: user.value.id
    })

    onMounted(() => {
      filters.year = new Date().getFullYear()
    })
    return {
      filters
    }
  }
}
</script>
<style scoped>
</style>
